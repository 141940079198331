import logo from '../src/images/logo.png';
import './App.css';
import truck from '../src/images/truck-lg.png';
import About from './components/About';
import { Box, Link } from '@mui/material';
import Employment from './components/Employment';

function App() {


  const form = "https://form.jotform.com/222427490411045";
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" style={{ minWidth: '50%'}} />

      <Box sx={{ backgroundColor: 'darkblue', color: 'white', width: '100%', padding: 2, fontWeight: 700}}>The #1 Transportation Company in Moncks Corner, SC.</Box>
      <Box sx={{ backgroundColor: 'white', color: 'black', width: '100%', padding: 1, fontWeight: 700}}>Open Positions Available!! <Link href={form}>Apply Now</Link></Box>

        {/* <p>
          Edit <code>src/App.js</code> and save to reload.
        </p> */}
       {/*  <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
              <img src={truck} style={{width: '100%', height: 'auto'}} alt="truck" />

      </header>
        <About />
        <Employment />
        
    </div>
  );
}

export default App;
