import React from 'react'
import { Box, Paper, Typography, useTheme, Link } from '@mui/material';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';

function About() {

  const theme = useTheme();

  const aboutSection = {
    backgroundColor: 'black',
    color: 'white',

    padding: 5,

    fontWeight: 700,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',

    }
  }
  return (
    <>
      <Box>
        <Typography sx={{ fontSize: 30, backgroundColor: 'black', color: 'white', pt: 10 }}><strong>SERVICES</strong></Typography>
        <Box sx={aboutSection}>
          <Paper sx={{ backgroundColor: '#1b1b1b', padding: 10, borderRadius: 5, color: 'white', mb: 5, pb: 8 }}>
            <BusinessOutlinedIcon fontSize='large' />
            <Typography>We specialize in intermodal drayage.</Typography>
            <Box sx={{ pt: 5, pb: 1, color: "white", mb: 7 }}>
              <strong>Call Us Now! 843-252-6225</strong>
            </Box>
            <Link sx={{ color: 'white', textDecoration: 'none', backgroundColor: '#4169e1', padding: 2, borderRadius: 5 }} href="mailto:travis@reedwayglobalenterprise.com">Get a quote now</Link>
          </Paper>
          <Paper sx={{ backgroundColor: '#1b1b1b', paddingLeft: 10, paddingRight: 10, borderRadius: 5, color: 'white', pt: 10, pb: 8 }}>
              <LocalShippingOutlinedIcon fontSize='large' />
              <Typography  sx={{ mb: 3}}>
                We service all ports and rails/ramps<br></br> in the Charleston area.
              </Typography>
              <Typography sx={{ mb: 7}}>
              <strong>Call Us Now! 843-252-6225</strong>
              </Typography>
              <Link sx={{ color: 'white', textDecoration: 'none', backgroundColor: '#4169e1', padding: 2, borderRadius: 5 }} href="mailto:travis@reedwayglobalenterprise.com">Get a quote now</Link>
          </Paper>
        </Box>

      </Box>
    </>
  )
}

export default About