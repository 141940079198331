import React from 'react'
import { Box, Paper, Typography, useTheme, Link } from '@mui/material';


function Employment() {

    const theme = useTheme();

    const aboutSection = {
        backgroundColor: 'black',
        color: 'white',
        fontWeight: 700,
        display: 'flex',
        pb: 10,
        flexDirection: 'row',
        justifyContent: 'space-around',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            width: '100%'
        }
    }
    const form = "https://form.jotform.com/222427490411045";
    return (
        <>
            <Box>
                <Typography sx={{ fontSize: 30, backgroundColor: 'black', color: 'white', pb: 5}}><strong>EMPLOYMENT</strong></Typography>
                <Box sx={aboutSection}>
                    <Paper sx={{
                        backgroundColor: '#1b1b1b',
                        width: '100%',
                        pt: 5,
                        pb: 5,
                        borderRadius: 5,
                        color: 'white',
                       
                        [theme.breakpoints.down('md')]: { marginBottom: 5 }
                    }}>

                        <Typography sx={{ textAlign: 'center', fontSize: 20}}><strong>Positions Available: </strong>
                            <ul style={{listStyle: 'none', paddingBottom: 25}}>Company Drivers: Local & OTR
                            <li>30% of loads</li>
                            <li>Flexible Schedule</li>
                            <li>Company Benefits</li>
                            </ul>
                            <ul style={{listStyle: 'none', paddingBottom: 25}}>O/O & Fleet owners
                            <li>80% of loads</li>
                            <li>Low insurance rates</li>
                            <li>Dedicated Lanes and more...</li>
                            </ul>
                            </Typography>
                        <Link href={form} sx={{color: 'yellow'}}>Apply Now</Link>

                    </Paper>

                </Box>

            </Box>
        </>
    )
}

export default Employment